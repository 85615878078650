import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import SearchResultCard from './SearchResultCard.js';
import PendingCard from './PendingCard.js';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';


import {getProducts} from "./../util/backend_caller";

// Potentially add a card action to open home depot link
//

function createSearchResult(image, title, price, unit, link, numInStock){
  return {image, title, price, unit, link, numInStock}
}


function SearchResults(props) {
	// obtain products on mount
	/*
        const query = Object.values(data)[0]
        getProducts(query, router.query.storeId)
              .then((products) => {

                var product_list = []

                for (var i = 0; i < products.length; i++) { 
                  const product = products[i]
                  const search_result = createSearchResult(product['thumbnails'][0][3], product['title'], product['price'], product['unit'], product['link'], product['quantity_in_stock'])
                  product_list.push(search_result)

                 }
                setSearchProducts(product_list);
                setSearchPending(false);
                

              })

              */

  useEffect(() => {

    const query = props.query

    if (props.searchPending){
	    getProducts(query, props.storeId)
	          .then((products) => {

	            var product_list = []

	            for (var i = 0; i < products.length; i++) { 
	              const product = products[i]
	              const search_result = createSearchResult(product['thumbnails'][0][3], product['title'], product['price'], product['unit'], product['link'], product['quantity_in_stock'])
	              product_list.push(search_result)

	             }
	            console.log(props.searchKey)
	            console.log(product_list)
	            props.setSearchProducts(props.searchKey, product_list);
	            
	          }).catch((error) => {
                // Show error alert message
                props.setFormAlert({
                  type: "error",
                  message: error.message,
                });
              })
      }

  }, []);

	return (

		<>
				
				<Container>
				<Grid container>
				<Grid item={true} xs={11}>
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', horizontalAlign: 'middle', height: '10vh'}}>
							{ props.searchPending && <Typography style={{fontSize: '5vh'}}> Searching for {props.query} </Typography>}
							{!props.searchPending && <Typography style={{fontSize: '5vh'}}> Search Results for {props.query} </Typography>}
							
					</div>
				</Grid>

				<Grid item={true} xs={1}>
					<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', horizontalAlign: 'middle', height: '10vh'}}>
						<Button onClick={() => props.removeSearchResult(props.searchKey)}>
							<CloseIcon/>
						</Button>
					</div>
				</Grid>
				<Divider/>
				</Grid>

				<Grid container spacing={3}>

				{props.searchPending && 
          		  [...Array(8)].map((row) => (
					<Grid item={true} xs={3}>
				    	<PendingCard/>
					</Grid>
          					)
          				)
          			
          		}

				{!props.searchPending && 
          		  props.searchProducts.map((row) => (
								
					<Grid item={true} xs={3}>
				    	<SearchResultCard searchKey={props.searchKey} title={row['title']} addToTable={props.addToTable} price={row['price']} link={row['link']} unit={row['unit']} numInStock={row['numInStock']} image={row['image']}/>
					</Grid>
          					)
          				)
          			
          		}

          		</Grid>

			</Container>

		</>

	)

}

export default SearchResults