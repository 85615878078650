import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import GoogleMapsSection from './GoogleMapsSection.js'

import { getClosestStores } from "./../util/backend_caller";


function HomeDepotCalculatorSearch(props) {
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [zip_coord, setZipCoord] = useState([35.5496939, -120.7060049])
  const [stores, setStores] = useState([])
  const { handleSubmit, register, errors, reset } = useForm();
  
  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);
    getClosestStores(Object.values(data)[0])
      .then((closestStores) => {
        reset();
        const zipcodeLocation = closestStores['zipcode_location']
        setZipCoord([zipcodeLocation['lat'], zipcodeLocation['lng']])  
        setStores(closestStores['stores'])
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        setPending(false);
      });
  };


  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={8}>
          {props.showNameField && (
            <Grid item={true} xs={12} md={6}>
              <TextField
                variant="outlined"
                type="text"
                label="Name"
                name="name"
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your zipcode",
                })}
              />
            </Grid>
          )}

          <Grid item={true} xs={12} md={props.showNameField ? 6 : 12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={pending}
            >
              {!pending && <span>{props.buttonText}</span>}

              {pending && <CircularProgress size={28} />}
            </Button>
          </Grid>
        </Grid>
      </form>


      <Grid container={2} spacing={4}>
        <Grid item={true} xs={4}>
          <GoogleMapsSection
            lat_center={zip_coord[0]}
            lng_center={zip_coord[1]}
            stores={stores}
          />
        </Grid>
      </Grid>
    </>
  );
}

//http://localhost:3000/dashboard for list
//https://mui.com/components/tables/ for table
//https://developers.google.com/maps/documentation/javascript/react-map


export default HomeDepotCalculatorSearch;
