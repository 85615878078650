import { React, useEffect } from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
import ReactGA from 'react-ga';

function PricingPage(props) {
  useEffect(() => {
    const TRACKING_ID = "UA-232328830-1";
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview('/pricing')
  });

  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="Choose the plan that makes sense for you. All plans include a 14-day free trial."
      />
    </>
  );
}

export default PricingPage;
