import Card from '@material-ui/core/Card';
import CircularProgress from "@material-ui/core/CircularProgress";

function PendingCard(props) {
	//<CircularProgress size={14} />


	return (
		    <Card>
		      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle', height: '50vh'}}>
		      	<CircularProgress />
		      </div>
		    </Card>
	)
}
export default PendingCard;