import React from "react";
import Meta from "./../components/Meta";
import HomeDepotSection from "./../components/HomeDepotSection";
import { requireAuth } from "./../util/auth";

//Use the contact page form to start building 

function HomeDepotPage(props) {
  return (
    <>
      <Meta title="Home Depot" />
      <HomeDepotSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Home Depot"
        buttonText="Search"
        showNameField={true}
        buttonColor="primary"
      />
    </>
  );
}

export default requireAuth(HomeDepotPage);
