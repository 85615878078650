import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';
import OpenInNewIcon from "@material-ui/icons/OpenInNew";


function SearchResultCard(props) {

	return (
		    <Card>
		      <CardMedia
		        component="img"
		        height="145"
		        image={props.image}
		      />
		      <CardContent>
		        <Typography gutterBottom variant="h6" component="div">
		           ${props.price}

		        </Typography>
		        <Typography variant="body2" color="text.secondary">

		        	{ props.title.length > 50 ? props.title.substring(0, 50) + '...': props.title + '\xa0'.repeat(53 - props.title.length) }
		        </Typography>
		      </CardContent>
	        <CardActions>
    			<Button size="small" onClick={() => props.addToTable(props.searchKey, props.title, props.unit, props.price, props.link, props.numInStock)}>Add to Table</Button>
    			<IconButton style={{ marginLeft: 'auto' }}
                  onClick={() => window.open(props.link, "Open Link")}
                  >
                  <OpenInNewIcon/>
                </IconButton>
    		</CardActions>
		    </Card>
	)
}
export default SearchResultCard