import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import contact from "./../util/contact";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from "@material-ui/core/IconButton";

// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useRouter } from "./../util/router";
import { getProductWithId } from "./../util/backend_caller";
import {CSVLink} from 'react-csv';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import SearchResults from './SearchResults'
import IncrementDecrementCell from './IncrementDecrementCell'

//https://stackoverflow.com/questions/48760815/export-to-csv-button-in-react-table
//https://www.nngroup.com/articles/input-steppers/
//look at sorting here
//https://mui.com/components/tables/ 

const prettyLink  = {
  color: '#fff',
  'text-decoration': 'none'
};

function getCSVData(rows){
  
  const data = [
    ['Room', 'Material', 'Quantity', 'Unit', 'Price', 'Total Price', 'Link', '# In Stock']

  ]

  for (var i = 0; i < rows.length; i++) {
    const row = rows[i]
    const totalPrice = parseFloat(row.price) * parseFloat(row.quantity)
    const data_row = [ row['room'], row['material'], row['quantity'], row['unit'], row['price'], totalPrice, row['link'], row['numInStock']]

    data.push(data_row)
  }
  return data

}


function createData(id, material, room, quantity, unit, price, link, numInStock, queryField) {
  return {id: id,  material, room, quantity, unit, price, link, numInStock, queryField, isEditMode: false, isLoading: false };
}


function getTotal(rows) {
  const total =  (rows.reduce((totalPrice, row) => totalPrice + (parseFloat(row.price) * parseFloat(row.quantity)), 0)).toFixed(2)
  return isNaN(total) ? 'COULD NOT COMPUTE' : total
}

function getProductId(link) {

  const split_s = link.split('/')
  return split_s[split_s.length - 1]
}






const CustomLinkTableCell = ({ row, name, onChange }) => {
  const { isEditMode } = row;
  return (
    <TableCell align="left" >
      {isEditMode ? (
        <TextField
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
        />
      ) : (
        <IconButton
          onClick={() => window.open(row.link, "Open Tab")}
          >
          <OpenInNewIcon/>
        </IconButton>
      )}
    </TableCell>
  );
};


const CustomTableCell = ({ row, name, onChange }) => {
  const { isEditMode } = row;
  return (
    <TableCell align="left" >
      {isEditMode ? (
        <TextField
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

function HomeDepotCalculatorSearch(props) {
  const router = useRouter()
  const [queries, setQueries] = useState([]);
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [previous, setPrevious] = React.useState({});
  const [rows, setRows] = useState([]);
  const [loadingRows, setLoadingRows] = useState([]);
  const { handleSubmit, register, errors, reset } = useForm();
  const [order, setOrder] = React.useState('asc');


  useEffect(() => {

    for (var i = 0; i < loadingRows.length; i++) { 

      const state_row = loadingRows[i]
      if (state_row.isLoading) {

        if (state_row.queryField === 'link'){
          getProductWithId(getProductId(state_row.link), router.query.storeId)
              //TODO: add an error if nothing is detected
              .then((product) => {
                const newRow = { ...state_row, 'unit': product['unit'], 'price': product['price'], 'link': product['link'] , 'material': product['title'], 'numInStock': product['quantity_in_stock'], isLoading: false}
                setLoadingRows(loadingRows => loadingRows.filter(row => row.id !== state_row.id))
                setRows(rows => [...rows.filter(row => row.id !== state_row.id), newRow]);

              }
            )
        }
      }
    }

  }, [loadingRows, router.query.storeId])



function addToTable(search_key, title, unit, price, link, numInStock){
  const newRow = createData(generateKey(title), title, 'General', 1, unit, price, link, numInStock, 'material');
  setRows(prevRows =>[...prevRows, newRow]);
  setQueries(prevQueries => prevQueries.filter(query => query.searchKey !== search_key));
}


function setSearchProducts(search_key, products){

  setQueries(
      prevQueries => 
      prevQueries.map(query => {
        if (query.searchKey === search_key) {
          return { ...query, searchProducts: products, searchPending: false};
        }
        return query;
      }
    )
  )
}


function removeSearchResult(search_key) {
  setQueries(prevQueries => prevQueries.filter(query => query.searchKey !== search_key));
}

const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
}

function createSortHandler(category)  {
  if (order === 'asc'){
      const newRows = [...rows].sort(
          (a, b) => {
            if (category === 'totalPrice'){
                return parseFloat(a['price']) * parseFloat(a['quantity']) - parseFloat(b['price']) * parseFloat(b['quantity'])
            }
            else if (typeof(a[category]) === 'string'  && typeof(b[category]) === 'string'){
              return a[category].localeCompare(b[category])
            }
            else if (typeof(a[category]) === 'number' && typeof(b[category]) === 'number'){
              return a[category] - b[category]
            }
            else if (typeof(a[category]) === 'number' && typeof(b[category]) === 'string'){

              return 1
            }
            else if (typeof(a[category]) === 'string' && typeof(b[category]) === 'number'){
              return -1
            }
            return 0
          }
          )

      setRows(newRows)
      setOrder('desc')
    }
  else if (order === 'desc'){
      const newRows = [...rows].sort(
          (a, b) => {
            if (category === 'totalPrice'){
                return  parseFloat(b['price']) * parseFloat(b['quantity']) - parseFloat(a['price']) * parseFloat(a['quantity'])
            }
            else if (typeof(a[category]) === 'string'  && typeof(b[category]) === 'string'){
              return b[category].localeCompare(a[category])
            }
            else if (typeof(a[category]) === 'number' && typeof(b[category]) === 'number'){
              return b[category] - a[category]
            }
            else if (typeof(a[category]) === 'number' && typeof(b[category]) === 'string'){

              return -1
            }
            else if (typeof(a[category]) === 'string' && typeof(b[category]) === 'number'){
              return 1
            }
            return 0
          }
          )
      setRows(newRows)
      setOrder('asc')
  }
  
}



  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);
    contact
      .submit(data)
      .then(() => {
        // Clear form
        reset();
        // Show success alert message
        setFormAlert({
          type: "success",
          message: Object.values(data),
        });

        const query = Object.values(data)[0]
        setQueries(queries =>[...queries, {searchKey: generateKey(query), query: query, searchPending: true, searchProducts: []}]);

        setPending(false);
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        
      });
  };

  const onToggleEditMode = (id, row_group, changeFunction) => {
    changeFunction(state => {
      return row_group.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const setValueDelta = (input_row, name, delta) => {
    setRows(rows => rows.map(row => {
        if (row.id === input_row.id) {
            return {...row, [name]: Math.max(0, row[name] + delta)}
        }
        else{
            return row
        }

    }))
  }
  const onChange = (e, row) => {
    console.log(e)
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;

    if (name === 'link'){
      setRows(rows => rows.filter(loaded_row => loaded_row.id !== id));
      setLoadingRows(loadingRows => [...loadingRows, {...row, [name]: value, isLoading: true, queryField: 'link' }])
    }

    else {
      const newRows = rows.map(row => {
        if (row.id === id) {
          if (name === 'link') {

            return { ...row, [name]: value, isLoading: true, queryField: 'link' };
          }
          else if (name === 'quantity' && !isNaN(parseInt(value))){

            return { ...row, [name]: parseInt(value) };
          }
          else{
            return { ...row, [name]: value };
          }
        }
        return row;
      });
      setRows(newRows);
    }
  };

  const onDelete = (id, row_group, changeFunction) => {
    changeFunction(row_group.filter(row => row.id !== id));
  }

  const onRoomChange = (e, input_row) => {
    const value = e.target.value;
    const newRows = rows.map(row => {
      if (row.id === input_row.id) {
          return { ...row, room: value };
        }
      return row;
    });
    setRows(newRows);
  }

  const onRevert = (id, row_group, changeFunction) => {
    const newRows = row_group.map(row => {
      if (row.id === id) {
        if (previous[id]){
          previous[id].isEditMode = false;
          return previous[id]
        }
        row.isEditMode = false;
        return row;
      }
      return row;
    });
    changeFunction(newRows);
    setPrevious(state => {
      delete state[id];
      return state;
    });
  };

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container={true} spacing={8}>
          {props.showNameField && (
            <Grid item={true} xs={12} md={6}>
              <TextField
                variant="outlined"
                type="text"
                label="Name"
                name="name"
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                inputRef={register({
                  required: "Please enter your name",
                })}
              />
            </Grid>
          )}

          <Grid item={true} xs={12} md={props.showNameField ? 6 : 12}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={pending}
            >
              {!pending && <span>{props.buttonText}</span>}

              {pending && <CircularProgress size={28} />}
            </Button>
          </Grid>
        </Grid>
      </form>

      { 
        queries.map((query) => (
          <SearchResults searchKey={query.searchKey} query={query.query} 
          searchPending={query.searchPending} 
          setSearchProducts={setSearchProducts} searchProducts={query.searchProducts}
          addToTable={addToTable} storeId={router.query.storeId}
          removeSearchResult={removeSearchResult}
          setFormAlert={setFormAlert}/>
        ))
      }
    
    { (rows.length + loadingRows.length) > 0 && (
    <Grid container={2} spacing={4}>
    <Grid item={true} xs={12}>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow
            hover
          >
            <TableCell align="left"></TableCell>
            <TableCell
              key='room'
              align='left'
              sortDirection={ order }
            >
              <TableSortLabel
                active={false}
                direction={order}
                onClick={() => createSortHandler('room')}
              >
                Room
              </TableSortLabel>
            </TableCell>
            <TableCell
              key='material'
              align='left'
              sortDirection={ order }
            >
              <TableSortLabel
                active={false}
                direction={order}
                onClick={() => createSortHandler('material')}
              >
                Material
              </TableSortLabel>
            </TableCell>

            <TableCell
              key='numInStock'
              align='left'
              sortDirection={ order }
            >
              <TableSortLabel
                active={false}
                direction={order}
                onClick={() => createSortHandler('numInStock')}
              >
                # In Stock
              </TableSortLabel>
            </TableCell>

            <TableCell
              key='quantity'
              align='left'
              sortDirection={ order }
            >
              <TableSortLabel
                active={false}
                direction={order}
                onClick={() => createSortHandler('quantity')}
              >
                Quantity
              </TableSortLabel>
            </TableCell>

            <TableCell
              key='unit'
              align='left'
              sortDirection={ order }
            >
              <TableSortLabel
                active={false}
                direction={order}
                onClick={() => createSortHandler('unit')}
              >
                Unit
              </TableSortLabel>
            </TableCell>

            <TableCell
              key='price'
              align='left'
              sortDirection={ order }
            >
              <TableSortLabel
                active={false}
                direction={order}
                onClick={() => createSortHandler('price')}
              >
                Price
              </TableSortLabel>
            </TableCell>

            <TableCell
              key='totalPrice'
              align='left'
              sortDirection={ order }
            >
              <TableSortLabel
                active={false}
                direction={order}
                onClick={() => createSortHandler('totalPrice')}
              >
                Total Price
              </TableSortLabel>
            </TableCell>

            <TableCell align="left">Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell>
              <IconButton
                aria-label="delete"
                onClick={() => onDelete(row.id, rows, setRows)}
              >
                <DeleteIcon />
              </IconButton>
              </TableCell>
              <TableCell align="left"
              >
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={row.room}
                      onChange={e => onRoomChange(e, row)}
                      label="Room"
                    >
                      <MenuItem value="General" primaryText="General">General</MenuItem>
                      <MenuItem value="Kitchen" primaryText="Kitchen">Kitchen</MenuItem>
                      <MenuItem value="Bathroom" primaryText="Bathroom">Bathroom</MenuItem>
                      <MenuItem value="Foundation" primaryText="Foundation">Foundation</MenuItem>
                    </Select>
                  </FormControl>

              </TableCell>
              <TableCell {...{ row, name: "material", onChange }} align="left">{row.material}</TableCell>
              <TableCell {...{ row, name: "numInStock", onChange }} align="left">{row.numInStock}</TableCell>
              <IncrementDecrementCell row={row} name='quantity' onChange={onChange} setValueDelta={setValueDelta}/>
              <TableCell {...{ row, name: "unit", onChange }} align="left">{row.unit}</TableCell>
              <TableCell {...{ row, name: "price", onChange }} align="left">{row.price}</TableCell>
              <TableCell align="left" style={{padding:'30px 10px 30px' }}>{isNaN(parseFloat(row.price) * parseFloat(row.quantity)) ? 'MATERIAL NOT FOUND' : (parseFloat(row.price) * parseFloat(row.quantity)).toFixed(2)}</TableCell>

              <CustomLinkTableCell {...{ row, name: "link", onChange }} align="left">
                <IconButton>
                  <OpenInNewIcon/>
                </IconButton>
              </CustomLinkTableCell>
            </TableRow>
          ))}
            <TableRow
              key='Nothing'
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">Total</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left">{getTotal(rows)}</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </Grid>
    <Grid container={true}>
      <Grid item={true} sm={11}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
          >
            <CSVLink data={getCSVData(rows)} style={prettyLink} filename="home_depot.csv"> Export </CSVLink>
          </Button>
        </Box>
      </Grid>
    </Grid>
    </Grid>
  )}
    </>
  );
}

//http://localhost:3000/dashboard for list
//https://mui.com/components/tables/ for table
//https://developers.google.com/maps/documentation/javascript/react-map

//add onChange to dropdown

export default HomeDepotCalculatorSearch;
