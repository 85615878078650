import React, { useEffect } from "react";
import Meta from "./../components/Meta";
import HomeDepotMapSection from "./../components/HomeDepotMapSection";
import { useAuth, requireAuth } from "./../util/auth";
import { useRouter } from "./../util/router";

//Use the contact page form to start building 

function HomeDepotMapPage(props) {
  const router = useRouter();
  const auth = useAuth();

  useEffect(() => {
    if (!auth.user.planIsActive) {
      router.push("/pricing");
    }
  }, []);


  return (
    <>
      <Meta title="Home Depot" />
      <HomeDepotMapSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Home Depot"
        buttonText="Enter zipcode"
        showNameField={true}
        buttonColor="primary"
      />
    </>
  );
}

export default requireAuth(HomeDepotMapPage);
