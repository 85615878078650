import React, { Component } from 'react'
import { Map, Marker,  GoogleApiWrapper, InfoWindow } from 'google-maps-react'
import { Redirect } from 'react-router-dom'


//https://medium.com/@aliglenesk/how-to-embed-a-google-map-in-your-react-app-23866d759e92
//https://stackoverflow.com/questions/53542673/react-google-maps-align-markerwithlabel-horizontally

/*

            <MarkerWithLabel
              labelStyle={{ textAlign: "center", width:labelSize.width + 'px', backgroundColor: "#7fffd4", fontSize: "14px", padding:  labelPadding + "px"}}
              labelAnchor={{ x: (labelSize.width/2) + labelPadding , y: 80 }}
              key={place.id}
              position={{ lat: place.lat, lng: place.lng }}>
              <span>{place.name}</span>
            </MarkerWithLabel>
*/


/*

		 	<Marker
		 		key={store.id}
	    		title={store.id}
	    		name={'SOMA'}
	    		position={{lat: store['lat'], lng: store['lng']}} />

*/
class GoogleMapsSection extends Component {
 constructor(props) {
 	super(props)
 	this.state = {
 		storeId: null
 	}

 }
 calculatorRedirect = (store) => {
 	this.setState({storeId: store.title})
 }
 render() {
     if (this.state.storeId != null) {
      return <Redirect to={'/home_depot/' + this.state.storeId} />
    }
	 return (
	 <div style={{height: '400px', width: '400px'}}>
	 	<Map google={this.props.google} 
	 		center={{
 			lat: this.props.lat_center,
 			lng: this.props.lng_center
 			}}
 			zoom={12}
	 	>
	 		{this.props.stores.map((store) => (
			 <Marker
		 		key={store.id}
				title={store.id}
				name={'SOMA'}
				position={{lat: store['lat'], lng: store['lng']}} 
				onClick={this.calculatorRedirect}
				label={store.street_address}
				>
				
		     	<InfoWindow>
		         <span>Something</span>
		     	</InfoWindow>
		    	
				</Marker>
	    	))}
	 	</Map>
	 </div>
	 );
 }
}


export default GoogleApiWrapper({
 apiKey: 'AIzaSyBLItGirN5qow6ZmgV3Ug2gLxtoA1rzT1Y'
})(GoogleMapsSection);