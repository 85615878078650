import { CustomError } from "./util";



export async function apiRequest(method = "GET", data={}) {
  fetch(process.env.REACT_APP_BACKEND_API_PATH + "/hello", {
    method: method
  }).then((response) => response.json()).then
    ((response) => {
      console.log(response);
      console.log(response.code);
      console.log(response.status);
      console.log(response.message);
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          console.log("ERROR")
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response.data;
      }
    });
}

export async function getProduct(query, storeId) {
  return fetch(process.env.REACT_APP_BACKEND_API_PATH + "/product?query=" + query + "&storeId=" + storeId, {
    method: "GET"
  }).then((response) => response.json()).then
    ((response) => {
      if (response.status === "error") {
        if (response.code === "auth/invalid-user-token") {
          console.log("ERROR")
        }
        throw new CustomError(response.code, response.message)
      } else {
        return response;
      }
    })
}

export async function getProductWithId(productId, storeId) {
  return fetch(process.env.REACT_APP_BACKEND_API_PATH + "/product_id_query?productId=" + productId + "&storeId=" + storeId, {
    method: "GET"
  }).then((response) => response.json()).then
    ((response) => {
      if (response.status === "error") {
        if (response.code === "auth/invalid-user-token") {
          console.log("ERROR")
        }
        throw new CustomError(response.code, response.message)
      } else {
        return response;
      }
    })
}


export async function getProducts(query, storeId){
  return fetch(process.env.REACT_APP_BACKEND_API_PATH + "/products?query=" + query + "&storeId=" + storeId, {
    method: "GET"
  }).then((response) => response.json()).then
    ((response) => {
      if (response.status === "error") {
        if (response.code === "auth/invalid-user-token") {
          console.log("ERROR")
        }
        throw new CustomError(response.code, response.message)
      } else {
        return response;
      }
    })

}


export async function getClosestStores(zipcode) {
  console.log(process.env.REACT_APP_BACKEND_API_PATH)
  return fetch(process.env.REACT_APP_BACKEND_API_PATH + "/closest_stores?zipcode=" + zipcode, {
    method: "GET"
  }).then((response) => {
    console.log(response);
    return response.json();
  }).then
    ((response) => {
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          console.log("ERROR")
        }

        throw new CustomError(response.code, response.message);
      } else {
        return response;
      }
    });
}
