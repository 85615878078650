import TableCell from '@material-ui/core/TableCell';
import TextField from "@material-ui/core/TextField";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from "@material-ui/core/Typography";
import TableRow from '@material-ui/core/TableRow';


import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";

//onChange has to change the row.quantity value
//when doubleClicked, we set row isEditMode to true
// when clicked out, we set row isEditMode to false
// some function that is basically (change this row's field by this much -1/+1)


function IncrementDecrementCell(props) {
    //props -> row, name, onChange, setValueDelta
    return (
        <ClickAwayListener
            onClickAway={e => props.onChange({target: {name: 'isEditMode', value: false}}, props.row)}
        >
            <TableCell align="left"

                style={{display: 'flex', padding:'40px 0px 40px' }}
            >
                <IconButton
                    size='small'
                    onClick={() => props.setValueDelta(props.row, props.name, -1)}
                >
                    <RemoveIcon/>
                </IconButton>
                {props.row['isEditMode'] ? (
                    <TextField
                      value={props.row[props.name]}
                      name={props.name}
                      onChange={e => props.onChange(e, props.row)}
                      onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                          props.onChange({target: {name: 'isEditMode', value: false}}, props.row)
                        }
                      }}


                    />
                ) : (
                    <Typography
                    onDoubleClick={e => props.onChange({target: {name: 'isEditMode', value: !props.row.isEditMode}}, props.row)}
                    >
                    {props.row[props.name]}
                    </Typography>
                )
                }

                <IconButton
                    size='small'
                    onClick={() => props.setValueDelta(props.row, props.name, 1)}
                >
                    <AddIcon/>
                </IconButton>
            </TableCell>
        </ClickAwayListener>
    )


}

export default IncrementDecrementCell;
